<template>

  <div class="row" ref="pollForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12">
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Title *"
                            name="title"
                            fou
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="question-row row" v-for="(question,questionIndex) in formData.questions">
              <div class="col-6">
                <fg-input type="text"
                          :key="questionIndex"
                          :label="'Question '+(questionIndex+1)"
                          v-model="question.text">
                </fg-input>
                <div v-if="questionIndex > 0" class="col-sm-1 align-self-center mt-3">
                  <l-button
                    @click="removeQuestion(questionIndex)"
                    type="danger" size="sm">
                    <i class="fa fa-trash"></i>
                  </l-button>
                </div>
              </div>
              <div class="col-6">
                <template class="row" v-for="(answer,answerIndex) in question.answers">
                  <fg-input type="text"
                            :key="answerIndex"
                            :label="'Answer '+(answerIndex+1)"
                            v-model="answer.text">
                  </fg-input>
                  <div v-if="answerIndex > 0" class="col-sm-1 align-self-center mt-3">
                    <l-button
                      @click="removeAnswer(question.answers,answerIndex)"
                      type="danger" size="sm">
                      <i class="fa fa-trash"></i>
                    </l-button>
                  </div>
                </template>
                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewAnswer(question.answers)"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
            </div>
            <l-button
              type="success"
              @click="addQuestion()"
              size="sm">
              <i class="fa fa-plus"></i>

            </l-button>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group pt-4">
                <label>Show in website </label>&nbsp;
                <l-switch v-model="formData.show_inside_pages">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/polls/list/'+formData.lang)" type="danger" wide>Cancel</l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Switch as LSwitch} from "@/components";

extend("required", {
  message: "{_field_} is required"
});
export default {
  components: {
    LSwitch,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        title: "",
        lang: null,
        show_inside_pages: false,
        questions: [],
      },

    };
  },
  created() {
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.pollForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Poll";
      this.getPoll();
    } else {
      this.editMode = false;
      this.formTitle = "Add Poll";
      this.loader.hide();
    }

  },


  methods: {

    async getPoll() {
      try {
        let data = {
          id: this.id,
          lang: this.formData.lang
        };
        let response = await this.axios.post("polls/get-poll", data);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Poll Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
        this.loader.hide();
      }
    },

    async submit() {
      if(!this.formData.questions || (this.formData.questions && !this.formData.questions.length)){
          this.$notify({
            message: "You have to add one Question at least",
            timeout: 2000,
            type: 'danger'
          });
          return;
      }
      const emptyQuestion = this.formData.questions.some(el => el.text === '');
      if (emptyQuestion) {
        this.$notify({
          message: "You have to fill all Questions or delete the empty one",
          timeout: 2000,
          type: 'danger'
        });
        return;
      }

      let emptyAnswer = false;
      let lessAnswers = false ;
      for (let question of this.formData.questions) {
        if (question.answers.some(el => el.text === '')) {
          emptyAnswer = true;
        }
        if(!question.answers || (question.answers && question.answers.length < 2)){
          lessAnswers = true;
        }
      }
      if(lessAnswers){
        this.$notify({
          message: "Every question must have 2 Answers at least",
          timeout: 2000,
          type: 'danger'
        });
        return;
      }
      if (emptyAnswer) {
        this.$notify({
          message: "You have to fill all Answers or delete the empty one",
          timeout: 2000,
          type: 'danger'
        });
        return;
      }
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("polls/update/" + this.id, this.formData);
        successMessage = "Poll Updated Successfully";
      } else {
        request = this.axios.post("polls/create", this.formData);
        successMessage = "Poll Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 2000,
          type: 'success'
        })
        this.$router.push("/polls/list/" + this.formData.lang);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      } finally {
        this.submitting = false;
      }
    },

    addQuestion() {
      this.formData.questions.push({'text': '', answers: []});
    },
    removeQuestion(index) {
      this.formData.questions.splice(index, 1);
    },
    addNewAnswer(answers) {
      answers.push({"text": "", "real_counter": 0, "edited_counter": 0});
    },
    removeAnswer(answers, index) {
      answers.splice(index, 1);
    },
  }
}
</script>

<style>
.question-row {
  margin-bottom: 20px;
}
</style>
