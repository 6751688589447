<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <div v-if="publishDates.length > 0">
      <h6>you publish this news :
      </h6>
      <p v-for="date in publishDates">{{date}}</p>
    </div>
    <h5 style="text-align: center">{{ message }}</h5>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'publish-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    message: {
      type: String,
      default: "Are you sue you want to publish this news?",
    },
    publishDates: {
      type: Array,
      default() {
        return []
      }
    }
  },

  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm');
    }

  }

}
</script>
