<template>
  <div :class="option.class">
    <div class="form-group has-label select-filter" >
      <v-select
        :multiple="option.multiple"
        label="name"
        item-text="name"
        item-value="id"
        class="style-chooser"
        v-model="option.value"
        :options="paginatedItems"
        :filterable="false"
        @search="onQuerySearch">
        <li slot="list-footer" class="pagination prev-next">
          <button class="btn btn-info" :disabled="!hasPrevPage" @click="prevPage">Prev</button>
          <button class="btn btn-info" :disabled="!hasNextPage" @click="nextPage">Next</button>
        </li>
      </v-select>


    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    vSelect,
  },
  watch: {
    'option.value': {
      handler: function (newValue) {
        this.selectItem();
      },
      deep: true
    }
  },
  props: ["option"],
  data() {
    return {
      itemsSearch: '',
      itemsOffset: 0,
      itemsLimit: 10,
      itemsCount: 0,
      itemList: [],
      selectedItems: '',
    }
  },
  computed: {
    paginatedItems(){
      return this.itemList;
    },
    hasNextPage() {
      if (this.itemList.length < 10) {
        return false
      }
      return true
    },
    hasPrevPage() {
      if (this.itemsOffset == 0){
        return false
      }
      return true
    },
  },
  created() {
  },
  mounted() {
    this.itemPaginated();
  },
  methods: {
    selectItem() {
      this.selectItems = this.option.value;
      // if (this.option.multiple){
      //   this.selectItems.map(item => item.id);
      // }
      // this.selectItems = this.option.value? this.option.value.id : '';
      this.$emit('input', this.selectItems);
    },
    itemPaginated(){
      let helperData = {
        offset: this.itemsOffset,
        limit: this.itemsLimit,
        search: this.itemsSearch,
        lang: this.option.lang
      };
      this.axios.post(this.option.url, helperData).then((response) => {
        this.itemList = response.data.dataList;
        this.itemsCount = response.data.dataCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    onQuerySearch(query) {
      this.itemsSearch = query
      this.itemsOffset = 0
      this.itemPaginated();
    },
    nextPage(){
      this.itemsOffset += this.itemsLimit;
      this.itemPaginated();
    },
    prevPage(){
      this.itemsOffset -= this.itemsLimit;
      this.itemPaginated();
    },
  }
}
</script>

<style>
.style-select > span:first-child{
  width: 100%;
}
.select-filter{
  margin: 5px;
}
.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}

.word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial, sans-serif;
  background: #fff;
}

.google_secret {
  align-items: center;
  justify-content: center;
  display: flex;
}

.el-dialog--center .el-dialog__footer {
  text-align: right;
}
.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}

.style-chooser .vs__deselect {
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}

.style-chooser .vs__dropdown-option--selected {
  color: #23CCEF;
}

.style-chooser .prev-next {
  margin-top: 15px;
}

.style-chooser .prev-next button {
  margin-right: 15px;
  margin-left: 10px;
}
</style>
