<template>

  <div class="row" ref="groupForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Source Name *"
                        name="name"
                        fou
                        @keyup="generateURL"
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="url"
              rules="required"
              name="The url"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="URL *"
                        name="url"
                        fou
                        v-model="formData.url">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
                    vid="website"
                    rules=""
                    name="The website"
                    v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Website"
                        name="website"
                        fou
                        v-model="formData.website">
              </fg-input>
            </ValidationProvider>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/sources/list/'+formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Switch as LSwitch} from "@/components";

extend("required", {
  message: "{_field_} is required"
});

export default {
    components: {
        LSwitch,
    },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        url: "",
        website: "",
        is_active: true,
        lang: 'ar',
      },
    };
  },

  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Source";
      this.getSource();
    } else {
      this.editMode = false;
      this.formTitle = "Add Source";
      this.loader.hide();
    }

  },


  methods: {
      getSource() {
        let data = {
            'id' : this.id,
            'lang' : this.formData.lang
        };
      this.axios.post("sources/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Source Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("sources/update/" + this.id, this.formData);
        successMessage = "Source Updated Successfully";
      } else {
        request = this.axios.post("sources/create", this.formData);
        successMessage = "Source Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/sources/list/" + this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateURL() {

        let newUrl = '';
        if (this.formData.name) {
            newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
            newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
            newUrl = newUrl.replace(/([-]+)/g, "-");
        }

        this.formData.url = newUrl;
    }

  }
}
</script>

<style>
</style>
