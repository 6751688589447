var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"NewsForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"category_id","rules":"required","name":"The Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"category_id","size":"large","filterable":"","clearable":"","placeholder":"Category *","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Category *',"list":_vm.categoryList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"title","rules":"required","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"title *","name":"title","fou":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"Date","rules":"required","name":"The Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"value-format":"YYYY-MM-DD HH:MM:SS","type":"datetime-local","error":failed ? errors[0]: null,"label":"Date","name":"date","fou":"","placeholder":"Chose live news date"},model:{value:(_vm.formData.date),callback:function ($$v) {_vm.$set(_vm.formData, "date", $$v)},expression:"formData.date"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"live_news_type","rules":"","name":"The Live news Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"live_news_type","size":"large","filterable":"","clearable":"","placeholder":"Live News Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Live News Type',"list":_vm.liveNewsTypes,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}],null,true)}),(_vm.formData.type === 'INTERNAL')?_c('div',[_c('paginated-select',{key:_vm.paginatedCompKey,staticClass:"style-chooser",attrs:{"name":"news_id","label":"title","labelTitle":'Choose News',"isMultiple":false,"apiUrl":_vm.lang +'/news/paginatedNews',"initData":_vm.formData.news_id},on:{"select-changed":_vm.setNews}}),(_vm.formData.news_id == null && _vm.submited)?_c('div',{staticClass:"text-danger invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" The Link is required ")]):_vm._e()],1):_vm._e(),(_vm.formData.type === 'EXTERNAL')?_c('ValidationProvider',{attrs:{"vid":"external_link","rules":"required","name":"The Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"link","name":"external_link","fou":""},model:{value:(_vm.formData.external_link),callback:function ($$v) {_vm.$set(_vm.formData, "external_link", $$v)},expression:"formData.external_link"}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"col-md-12 col-sm-12 extra-fields"},[_c('h4',[_vm._v("Settings")]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Important")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.important),callback:function ($$v) {_vm.$set(_vm.formData, "important", $$v)},expression:"formData.important"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Breaking News")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.breaking_news),callback:function ($$v) {_vm.$set(_vm.formData, "breaking_news", $$v)},expression:"formData.breaking_news"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)])])])])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/live-news/list/'+_vm.lang)}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }