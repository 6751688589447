<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <div v-if="pushNotificationDates.length > 0">
      <h6>Last push :
      </h6>
      <p v-for="date in pushNotificationDates">{{date}}</p>
    </div>
    <ValidationProvider
      vid="title"
      rules="required"
      name="The Title"
      v-slot="{ passed, failed,errors }">
      <fg-input type="text"
                :error="failed ? errors[0]: null"
                label="title"
                name="title"
                fou
                v-model="newsTitle">
      </fg-input>
    </ValidationProvider>
    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'push-notification-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    title: {
      type: String,
      default: "",
    },
    pushNotificationDates: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data(){
    return{
      newsTitle: '',
    }
  },
  watch: {
    title: {
      handler: function (newValue) {
        this.newsTitle = this.title;
      },
      deep: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm', this.newsTitle);
    }

  }

}
</script>
