<template>

  <div class="row" ref="NewsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  vid="category_id"
                  rules="required"
                  name="The Category"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="category_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Category *"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Category *'"
                    :list="categoryList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.category_id">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="title *"
                            name="title"
                            fou
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="Date"
                  rules="required"
                  name="The Date"
                  v-slot="{ passed, failed,errors }">
                  <fg-input v-model="formData.date"
                            value-format="YYYY-MM-DD HH:MM:SS"
                            type="datetime-local"
                            :error="failed ? errors[0]: null"
                            label="Date"
                            name="date"
                            fou
                            placeholder="Chose live news date"
                  >
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="live_news_type"
                  rules=""
                  name="The Live news Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="live_news_type"
                    size="large"
                    filterable
                    clearable
                    placeholder="Live News Type"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Live News Type'"
                    :list="liveNewsTypes"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="formData.type">
                  </fg-select>
                </ValidationProvider>
                <div v-if="formData.type === 'INTERNAL'">
                  <paginated-select
                    name="news_id"
                    label="title"
                    class="style-chooser"
                    :labelTitle="'Choose News'"
                    :isMultiple="false"
                    @select-changed="setNews"
                    :apiUrl="lang +'/news/paginatedNews'"
                    :initData="formData.news_id"
                    :key="paginatedCompKey"
                  >
                  </paginated-select>
                  <div v-if="formData.news_id == null && submited"
                       class="text-danger invalid-feedback" style="display: block;"> The Link is required
                  </div>
                </div>

                <ValidationProvider
                  v-if="formData.type === 'EXTERNAL'"
                  vid="external_link"
                  rules="required"
                  name="The Link"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           label="link"
                           name="external_link"
                           fou
                           v-model="formData.external_link">
                  </fg-text>
                </ValidationProvider>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="col-md-12 col-sm-12 extra-fields">
                  <h4>Settings</h4>

                  <div class="col-md-12">
                    <div class="form-group pt-4">
                      <label>Important</label>&nbsp;
                      <l-switch v-model="formData.important">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                    <div class="form-group pt-4">
                      <label>Breaking News</label>&nbsp;
                      <l-switch v-model="formData.breaking_news">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/live-news/list/'+lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {Switch as LSwitch} from "@/components";
import {mapGetters} from "vuex";
import PaginatedSelect from "@/components/paginatedSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    FgSelect,
    LSwitch,
    PaginatedSelect
  },

  data() {
    return {
      editMode: false,
      submited: false,
      loader: {},
      id: undefined,
      paginatedCompKey: 0,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        news_id: null,
        type: null,
        description: "",
        external_link: "",
        date: null,
        category_id: null,
        important: false,
        breaking_news: false,
      },
      liveNewsTypes: [],
      newsList: [],
      categoryList: [],
      lang: null,
    };
  },
  created() {
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1);
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.newsForm
    });
    this.axios.post(this.lang + "/live-news/builder").then((response) => {
      this.liveNewsTypes = response.data.liveNewsTypes;
      this.categoryList = response.data.categories;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Live News";
        this.getNews();
      } else {
        this.editMode = false;
        this.formTitle = "Add Live News";
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        this.formData.date = now.toISOString().slice(0, -1);
        ;
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    });
  },
  methods: {
    getNews() {
      this.axios.get(this.lang + "/live-news/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.formData.news_id = response.data.news;
        this.formData.date = this.vueDate(this.formData.date);
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Live News Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      this.submited = true;
      if (this.formData.type === "INTERNAL" && !this.formData.news_id) {
        return;
      }
      let request;
      let successMessage;
      this.submitting = true;
      let formData = {...this.formData};
      formData.date = this.laravelDate(formData.date);
      if (formData.type === "INTERNAL") {
        formData.news_id = this.formData.news_id.id;
        formData.external_link = null;
      } else {
        formData.news_id = null;
      }
      if (this.editMode === true) {
        request = this.axios.put(this.lang + "/live-news/update/" + this.id, formData);
        successMessage = "Live News Updated Successfully";
      } else {
        request = this.axios.post(this.lang + "/live-news/create", formData);
        successMessage = "Live News Added Successfully";
      }
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/live-news/list/" + this.lang);
      }).catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate(date) {
      return date ? date.replace(' ', 'T') : date;
    },
    laravelDate(date) {
      return date ? date.replace('T', ' ') : date;
    },
    setNews(selectedNews) {
      this.formData.news_id = selectedNews
    },

  }
}
</script>

<style>
.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0;
  padding-bottom: 3px;

}

.extra-fields > h4 {
  padding: 20px;
}

</style>
