<template>
  <div class="col-12 d-flex justify-content-end flex-wrap">

<!--    <div class="">-->
<!--      <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>-->
<!--    </div>-->

    <ul class="pagination" :class="paginationClass">
      <li class="page-item prev-page" :class="{disabled: prevPageUrl === null}">
        <a class="page-link" aria-label="Previous" @click="prevPage" style="font-size: 35px">
          <span>«</span>
        </a>
      </li>
      <li class="page-item page-pre next-page" :class="{disabled: nextPageUrl === null}">
        <a class="page-link" aria-label="Next" @click="nextPage" style="font-size: 35px">
          <span>»</span>
        </a>
      </li>
    </ul>

  </div>
</template>
<script>
export default {
  name: 'simple-nl-pagination',
  props: {
    color: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple'].includes(value)
      }
    },
    // pageCount: {
    //   type: Number,
    //   default: 0
    // },
    perPage: {
      type: Number,
      default: 10
    },
    // total: {
    //   type: Number,
    //   default: 0
    // },
    nextPageUrl: {
      type: String,
      default: null
    },
    prevPageUrl: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    paginationClass() {
      return `pagination-${this.color}`
    },
    // totalPages() {
    //   if (this.pageCount > 0) return this.pageCount
    //   if (this.total > 0) {
    //     return Math.ceil(this.total / this.perPage)
    //   }
    //   return 1
    // },
    // pagesToDisplay() {
    //   if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
    //     return this.totalPages
    //   }
    //   return this.defaultPagesToDisplay
    // },

    // minPage() {
    //   if (this.value >= this.pagesToDisplay) {
    //     const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
    //     const newMaxPage = pagesToAdd + this.value
    //     if (newMaxPage > this.totalPages) {
    //       return this.totalPages - this.pagesToDisplay + 1
    //     }
    //     return this.value - pagesToAdd
    //   } else {
    //     return 1
    //   }
    // },
    // maxPage() {
    //   if (this.value >= this.pagesToDisplay) {
    //     const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
    //     const newMaxPage = pagesToAdd + this.value
    //     if (newMaxPage < this.totalPages) {
    //       return newMaxPage
    //     } else {
    //       return this.totalPages
    //     }
    //   } else {
    //     return this.pagesToDisplay
    //   }
    // },
    // to () {
    //   let highBound = this.from + this.perPage
    //   if (this.total < highBound) {
    //     highBound = this.total
    //   }
    //   return highBound
    // },
    // from () {
    //   return this.perPage * (this.value - 1)
    // },

  },
  data() {
    return {
      defaultPagesToDisplay: 5
    }
  },
  methods: {
    // range(min, max) {
    //   let arr = []
    //   for (let i = min; i <= max; i++) {
    //     arr.push(i)
    //   }
    //   return arr
    // },
    // changePage(item) {
    //   this.$emit('input', item)
    // },
    nextPage() {
      if (this.nextPageUrl != null) {
        this.$emit('input', this.nextPageUrl)
      }
    },
    prevPage() {
      if (this.prevPageUrl != null) {
        this.$emit('input', this.prevPageUrl)
      }
    }
  }
}
</script>
