<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Live News List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/create'])"
                        :to="'/live-news/create/'+lang"
                        class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :advanceSearchFilter="advanceSearchFilter"
            :row-key="'id'"
            :key="componentKey"
            :api-url="lang+'/live-news/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="publish">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/push-notification']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/push-notification', scope.row.creator_id)"
                   v-tooltip.top-center="'Push Notification'"
                   class="btn-success btn-simple btn-link"
                   style="color:#2d88ff;font-size: 24px;cursor: pointer;margin-right: 5px"
                   @click="openPushNotificationModal(scope.row.id, scope.row.title, scope.row.push_notification_dates)"><i class="nc-icon nc-notification-70"></i></a>


              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/update', scope.row.creator_id)"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/live-news/edit/'+scope.row.id+'/'+lang">
                  <i class="fa fa-edit"></i>
                </router-link>
                <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['live_news_'+lang+'_logs_view'])"
                              v-tooltip.top-center="'Log'"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/live_news_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/live-news/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/live-news/delete', scope.row.creator_id)"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this live news?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

      <push-notification-modal :visible="pushNotificationModalVisibility"
                               :pushNotificationDates="pushNotificationDates"
                               :title="pushNotificationTitle"
                               @close="closePushNotificationModal()"
                               @confirm="confirmPushNotification">
      </push-notification-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import PushNotificationModal from "../../../components/Modals/PushNotificationModal";


export default {
  components: {
    PushNotificationModal,
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    GeneralDataTable,
    FgSelect,
  },

  data() {
    return {
      tableColumns: [],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter:[],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: '',
      fixedColumn: '',
      componentKey: 0,
      pushNotificationModalVisibility: false,
      pushNotificationDates: [],
      toPushNotificationId: undefined,
      pushNotificationTitle: "",
    }

  },
  created(){
      this.handlePageComponents();
  },
  watch:{
      $route (to, from){
          this.componentKey++;
         this.handlePageComponents();
      }
  },
  methods: {
    handlePageComponents(){
        this.responsiveViewPort();
        const path = window.location.href;
        this.lang = path.substring(path.lastIndexOf('/') + 1)
        this.axios.post(this.lang+"/live-news/builder").then((response) => {
            this.categoryList = response.data.categories;
            this.setAdvanceSearch();
        }).catch((error) => {
            console.error(error);
        });

    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete(this.lang+"/live-news/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Live News deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },

    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    setAdvanceSearch(){
      this.advanceSearchFilter = [
        {
          key: 'live_news_'+this.lang+'.title',
          type: 'text',
          frontType:'simple',
          label: 'Title',
          class: 'col-md-6 col-sm-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.category_id',
          type: 'multiple-select',
          frontType:'select-with-fixed-list',
          strategy: '',
          label: 'Category',
          options: this.categoryList,
          class: 'col-md-6 col-sm-6 col-xs-12',
          multiple: true ,
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'Created after',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'Created before',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.date',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'after date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'live_news_'+this.lang+'.date',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'before date',
          class: 'col-md-6 col-xs-12',
          value: null,
        },



      ];
    },
    openPushNotificationModal(id, title, pushNotificationDates) {
      this.pushNotificationModalVisibility = true;
      this.toPushNotificationId = id;
      this.pushNotificationTitle = title
      this.pushNotificationDates = pushNotificationDates;
    },

    closePushNotificationModal() {
      this.pushNotificationModalVisibility = false;
    },
    confirmPushNotification(title) {
      let data = {
        'live_news_id': this.toPushNotificationId,
        'notification_body': title
      }
      this.axios.post(this.lang+"/live-news/push-notification", data).then((response) => {
        this.$notify({
          message: "Notification added successfully",
          timeout: 1000,
          type: 'success'
        });
        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closePushNotificationModal();
      this.toPushNotificationId = undefined;
    },
    responsiveViewPort(){
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          this.fixedColumn = null;
          this.tableColumns = [
              {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
              {label: 'Type', value: 'type', minWidth: '200', align: 'center'},
          ];
      }else{
          this.fixedColumn = 'right';
          this.tableColumns = [
              {label: 'Title', value: 'title', minWidth: '200', align: 'center'},
              {label: 'Category', value: 'category.name', minWidth: '200', align: 'center'},
              {label: 'Type', value: 'type', minWidth: '200', align: 'center'},
              {label: 'Date', value: 'date', minWidth: '200', align: 'center'},
          ];
      }
  }

  },

}
</script>
