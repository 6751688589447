<template>
  <div ref="table">

    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

      <el-select
        class="select-default mb-3"
        style="width: 200px"
        v-model="paginationData.pageSize"
        @change="getData('changePageSize')"
        placeholder="Per page">
        <el-option
          class="select-default"
          v-for="item in paginationData.perPageOptions"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>


      <el-input type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                @change="getData('updateSearch')"
                aria-controls="datatables"/>
    </div>
    <div class="col-12" v-if="advanceSearchFilter">
      <el-collapse>
        <el-collapse-item title=" Advance Search" name="1">
          <advance-search class="mt-4" id="advance-search" url="" @filter-data="advanceSearchAction"
                          :filters="advanceSearchFilter"></advance-search>
        </el-collapse-item>
      </el-collapse>
    </div>


    <div class="col-sm-12">
      <el-table stripe
                style="width: 100%;"
                @sort-change="sortChange"
                v-loading="loading"
                @selection-change="handleSelectionChange"
                :data="tableData"
                :row-key="rowKey"
                border>
        <el-table-column
          v-if="selectable"
          type="selection"
          align="center"
          width="60">
        </el-table-column>
        <slot></slot>

      </el-table>
    </div>

    <div style="padding: 10px">
      <hr>
      <simple-nl-pagination
        class="pagination-no-border"
        v-model="paginationData.currentPage"
        :per-page="paginationData.pageSize"
        :next-page-url="paginationData.next_page"
        :prev-page-url="paginationData.prev_page"
        @input="changePageUrl"
      >

      </simple-nl-pagination>
    </div>
  </div>
</template>
<script>
import NlPagination from "@/components/NewPagination";
import {Collapse, CollapseItem, Option, Select, Table, TableColumn,} from "element-ui";
import Sortable from 'sortablejs';
import AdvanceSearch from '@/components/AdvancedSearch/AdvanceSearch.vue';
import SimpleNlPagination from "./SimpleNewPagination";


export default {

  props: {
    apiUrl: {
      required: true
    },
    selectable: {
      default: false
    },
    sortable: {
      default: false
    },
    rowKey: {
      required: false
    },
    method: {
      default: "POST"
    },
    advanceSearchFilter: {
      required: false
    },
    lang: {
      required: false,
      default: null,
    },
    dataParam: {
      required: false
    },
  },
  components: {
    SimpleNlPagination,
    NlPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,

    Sortable,
    AdvanceSearch,
  },
  data() {
    return {

      paginationData: {
        pageSize: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        totalItems: 0,
        prev_page: null,
        next_page: null,
      },

      sort: {
        fieldName: null,
        order: null
      },
      advanceSearchFilterLocal: {},
      searchQuery: '',
      filters: null,
      tableData: [],
      loading: false,
      sortableObj: null,
      currentApiUrl: this.apiUrl,
    };
  },


  mounted() {
    this.getData();
    if (this.sortable) {
      this.createDragAndDropSorting();
    }
  },

  methods: {

    getData: function (action, selectedUrl=this.apiUrl + '?page=1', advance = false) {
      this.loading = true;
      switch (action) {

        case 'changePageNumber' :
          if (this.paginationData.next_page){
            this.updatePageUrl(selectedUrl);
          }else if (this.paginationData.prev_page){
            this.updatePageUrl(selectedUrl);
          }
          break;

        case 'changePageSize':
          this.updatePageUrl(selectedUrl);
          break;

        case 'updateSearch':
          this.updatePageUrl(selectedUrl);
          break;

        case 'updateSort':
          this.updatePageUrl(selectedUrl);
          break;

        case 'updateData':
          this.resetSort();
          this.updatePageUrl(selectedUrl);
          break;
          default:
            this.updatePageUrl(selectedUrl);
      }

      this.currentApiUrl = this.currentApiUrl.replace('http:', 'https:');
      let requestUrl = this.currentApiUrl +
        "&pageSize=" + this.paginationData.pageSize + "&searchQuery=" + this.searchQuery +
        "&sortBy=" + this.sort.fieldName + "&sortDir=" + this.sort.order;
      if (this.lang != null) {
        requestUrl += "&lang=" + this.lang;
      }


      let request;

      let advanceSearch = this.advanceSearchFilter;
      let dataParam = this.dataParam;
      let searchQuery = this.searchQuery;
      if (advance) {
        searchQuery = null;
      }
      if (this.method === 'GET') {
        request = this.axios.get(requestUrl);
      } else {
        request = this.axios.post(this.currentApiUrl, {
          filters: this.filters,
          advanceSearchFilter: advanceSearch,
          dataParam: dataParam,
          // page: this.paginationData.currentPage,
          pageSize: this.paginationData.pageSize,
          next_page: this.paginationData.next_page,
          prev_page: this.paginationData.prev_page,
          searchQuery: searchQuery,
          sort: this.sort,
          lang: this.lang
        });
      }
      request.then((response) => {
        this.tableData = response.data.data;
        this.paginationData.totalItems = response.data.pagination.total;
        this.paginationData.next_page = response.data.pagination.next_page;
        this.paginationData.prev_page = response.data.pagination.prev_page;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });

    },

    updatePageUrl(url) {
      this.currentApiUrl = url;
    },

    handleSelectionChange(selectedRows) {
      let selectedIds = selectedRows.map((row) => {
        return row.id;
      });
      this.$emit('selection-change', selectedIds)
    },

    sortChange(column) {
      if (column.order != null) {
        this.sort.fieldName = column.prop;
        this.sort.order = column.order;
      } else {
        this.resetSort();
      }
      this.getData("updateSort")
    },
    resetSort() {
      this.sort.fieldName = null;
      this.sort.order = null;
    },

    createDragAndDropSorting() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      this.sortableObj = Sortable.create(tbody, {
        onEnd({newIndex, oldIndex}) {
          if (oldIndex !== newIndex) {
            const currRow = _this.tableData.splice(oldIndex, 1)[0]
            _this.tableData.splice(newIndex, 0, currRow);
            _this.$emit('reorder', _this.tableData);
          }
        }
      });
    },

    destroyDragAndDropSorting() {
      this.sortableObj.destroy();
    },
    advanceSearchAction(value) {
      this.advanceSearchFilterLocal = value;
      this.getData('advaceSearch', this.apiUrl + '?page=1', true);
    },
    changePageUrl(url){
      this.getData('changePageNumber', url);
    }

  },

};
</script>
